import { getAxios } from "../../HttpRequest";

const getDefaultState = () => {
    return {
        users: [],
        user: null,
        reload: true
    }
}

const state = getDefaultState();

export default {
    namespaced: true,
    state,
    getters: {
        users(state) {
            return state.users;
        },
        user(state) {
            return state.user;
        },
        reload(state) {
            return state.reload;
        }
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState())
        },
        users(state, users) {
            state.users = users;
        },
        user(state, user) {
            state.user = user;
        },
        reload(state, reload) {
            state.reload = reload;
        }
    },
    actions: {
        async users({commit}, params) {
            const axios = await getAxios();
            return axios.get('/members', {params}).then((response) => {
                commit('users', response.data.members.data);
                commit('reload', false);
                return response.data.members;
            });
        },
        async user({commit}, params) {
            const axios = await getAxios();
            return axios.get('/member', {params}).then((response) => {
                commit('user', response.data.user);
                return response.data;
            });
        }
    }
};