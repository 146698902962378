import { getAxios } from "../../HttpRequest";

export default {
    namespaced: true,
    state: {
        tarife: [],
        countries: [],
    },
    getters: {
        tarife(state) {
            return state.tarife;
        },
        countries(state) {
            return state.countries;
        }
    },
    mutations: {
        tarife(state, tarife) {
            state.tarife = tarife;
        },
        countries(state, countries) {
            state.countries = countries;
        }
    },
    actions: {
        async tarife({commit}) {
            const axios = await getAxios();
            return axios.get('/tarife').then(response => {
                commit('tarife', response.data.list);
                return response.data;
            });
        },
        async countries({commit}) {
            const axios = await getAxios();
            return axios.get('/countries').then(response => {
                commit('countries', response.data.countries);
            });
        },
        async register(context, data) {
            const axios = await getAxios();
            return axios.post('/register', data);
        },
        async emailcode(context, data) {
            const axios = await getAxios();
            return axios.post('/register/code', data);
        },
        async companyregister(context, data) {
            const axios = await getAxios();
            return axios.post('/companyregister', data);
        }
    }
};