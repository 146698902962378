import moment from 'moment';
import 'moment/locale/de';
import 'moment-timezone';

export default {
    install: async (app) => {
        moment.locale('de');
        moment.tz("Europe/Berlin");
        app.config.globalProperties.$moment = moment;
    }
}