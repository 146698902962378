import { getStorage } from './Storage';
import Store from "./store";

export async function setToken(token) {
    if (token === "" || token === undefined) {
        token = null;
    }

    const storage = await getStorage();
    return await storage.set('token', token);
}

export async function setTmpToken(token) {
    if (token === "" || token === undefined) {
        token = null;
    }

    const storage = await getStorage();
    return await storage.set('tmptoken', token);
}

export async function getToken() {
    const storage = await getStorage();
    return await storage.get('token');
}

export async function getTmpToken() {
    const storage = await getStorage();
    return await storage.get('tmptoken');
}

export async function checkStatus() {
    Store.commit('loading', {message: 'Bitte warten ...'});
    const token = await getToken();
    if (token === null) {
        Store.commit('ready');
        return false;
    }

    return Store.dispatch('auth/check').then(() => {
        return !!Store.getters['auth/isUser'];
    });
}

async function logout() {
    Store.commit('ready', false);
    Store.dispatch('layout', {status: false});
    Store.commit('loading', {message: 'Bitte warten ...'});
    await Store.dispatch('auth/logout');
    window.location.href = "/";
}

export async function authRedirect(to, from) {
    const status = await checkStatus();
    Store.dispatch('layout', {status});
    if (to.meta.needAuth) {
        if (to.name === "logout") {
            window.setTimeout(async () => {
                logout();
            }, 500);

            return { name: "login" };
        }

        if (status) {
            const user = Store.getters['auth/user'];
            if(to.meta.level !== undefined && user.level < 110) {
                return {name: "dashboard"};
            }

            if (user.level > 100 && user.completed_at === null && to.name !== "companyregister") {
                return {name: "companyregister"};
            } else if (user.need_2fa === true && to.name !== "2fa") {
                if (user.settings.authenticator === "sms") {
                    await Store.dispatch('auth/init2fa', {
                        type: user.settings.authenticator
                    });
                }

                return {name: "2fa"};
            } else if (from.name === undefined && to.name !== "companyregister" && to.name !== "dashboard" && to.name !== "2fa") {
                return {name: "dashboard"};
            }

            return true;
        }

        if (to.name !== "login") {
            return {name: "login"};
        }
    }

    if (status) {
        return {name: "dashboard"};
    }

    if (to.name === "start") {
        return {name: "login"};
    }

    return true;
}