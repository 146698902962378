import { getAxios } from '../../HttpRequest';
import { setTmpToken, setToken } from '../../Auth';
import { getPlatforms } from "@ionic/vue";

export default {
    namespaced: true,
    state: {
        user: null,
        checking: false
    },
    getters: {
        isGuest(state) {
            return state.user === null;
        },
        isUser(state) {
            return state.user !== null;
        },
        user(state) {
            return state.user;
        }
    },
    mutations: {
        checking(state, checking) {
            state.checking = checking;
        },
        async user(state, user) {
            if (user === "" || user === undefined) {
                user = null;
            }

            state.user = user;
        },
        disableTestphase(state) {
            state.user.testphase.active = false;
        }
    },
    actions: {
        async session() {
            const axios = await getAxios();
            return axios.post('/session', {
                'type': getPlatforms()
            });
        },
        async login({commit}, data) {
            const axios = await getAxios();
            return axios.post('/login', data).then(async response => {
                if (response.data.success === true) {
                    await setToken(response.data.token);
                    return response.data.user;
                }

                commit('user', null);
                return response.data;
            });
        },
        async init2fa(context, data) {
            const axios = await getAxios();
            return axios.get('/2fa', {params: data}).then(async response => {
                return response.data;
            });
        },
        async check2fa({commit}, data) {
            const axios = await getAxios();
            return axios.get('/2fa', {params: data}).then(async response => {
                commit('user', response.data.user);
                return response.data;
            });
        },
        async logout({commit}) {
            const axios = await getAxios();
            return axios.post('/logout').then(async () => {
                commit('user', null);
                commit('checking', false);
                commit('user/reset', null, {
                    root: true
                });

                commit('users/reset', null, {
                    root: true
                });

                commit('company/reset', null, {
                    root: true
                });

                await setTmpToken(null);
                return await setToken(null);
            });
        },
        async check({commit}) {
            const axios = await getAxios();
            return axios.post('/check').then(async response => {
                let user = response.data.user;
                if (response.data.success === false || user === undefined) {
                    user = null;
                }

                if (user === null) {
                    await setToken(null);
                }

                commit('user', user);

                return user;
            });
        },
        async passwordreset(context, data) {
            const axios = await getAxios();
            return axios.post('/passwordreset', data);
        }
    }
};
