import { getAxios } from "../../HttpRequest";

const getDefaultState = () => {
    return {
        datatype: "",
        entry: {},
        limits: {},
        telephone: [],
        messages: [],
        contacts: [],
        contacts_types: [],
        state: '',
        states: [],
        links: [],
        mails: [],
        faq: [],
        forms: [],
        new_messages: 0
    }
}

const state = getDefaultState();

export default {
    namespaced: true,
    state,
    getters: {
        datatype(state) {
            return state.datatype;
        },
        messages(state) {
            return state.messages;
        },
        entry(state) {
            return state.entry;
        },
        limits(state) {
            return state.limits;
        },
        telephone(state) {
            return state.telephone;
        },
        contacts(state) {
            return state.contacts;
        },
        contacts_types(state) {
            return state.contacts_types;
        },
        states(state) {
            return state.states;
        },
        state(state) {
            return state.state;
        },
        links(state) {
            return state.links;
        },
        mails(state) {
            return state.mails;
        },
        faq(state) {
            return state.faq;
        },
        forms(state) {
            return state.forms;
        },
        new_messages(state) {
            return state.new_messages;
        }
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState())
        },
        datatype(state, datatype) {
            state.datatype = datatype;
        },
        messages(state, messages) {
            state.messages = messages;
        },
        entry(state, entry) {
            state.entry = entry;
        },
        limits(state, limits) {
            state.limits = limits;
        },
        telephone(state, telephone_numbers) {
            state.telephone = telephone_numbers;
        },
        contacts(state, contacts) {
            state.contacts = contacts;
        },
        contacts_types(state, contacts_types) {
            state.contacts_types = contacts_types;
        },
        states(state, states) {
            state.states = states;
        },
        state(state, state_text) {
            state.state = state_text;
        },
        links(state, link_list) {
            state.links = link_list;
        },
        mails(state, mail_list) {
            state.mails = mail_list;
        },
        faq(state, faq_list) {
            state.faq = faq_list;
        },
        forms(state, form_list) {
            state.forms = form_list;
        },
        new_messages(state, new_messages) {
            state.new_messages = new_messages;
        }
    },
    actions: {
        async userEntries({commit, state}, params = {}) {
            if (state.datatype !== "") {
                params.datatype = state.datatype;
            }

            const axios = await getAxios();
            return axios.get('/userentries', {params}).then(response => {
                if (response.data.success) {
                    const contents = response.data.contents;
                    commit('limits', response.data.limits);
                    commit('telephone', contents.telephone);
                    commit('states', contents.states);
                    commit('state', contents.state);
                    commit('links', contents.links);
                    commit('mails', contents.mails);
                    commit('faq', contents.faq);
                    commit('contacts', contents.contacts);
                    commit('contacts_types', contents.contacts_types);
                    commit('forms', contents.forms);
                    commit('new_messages', contents.new_messages);
                }
            });
        },
        async memberlist() {
            const axios = await getAxios();
            return axios.get('/members/list').then(response => {
                return response.data;
            });
        },
        async updateContacts(context, data) {
            const axios = await getAxios();
            return axios.post('/contacts/' + data.type, data).then(response => {
                return response.data;
            });
        },
        async getMessages({commit}, params) {
            const axios = await getAxios();
            return axios.get('/messages', { params }).then(response => {
                commit('messages', response.data.messages);
                return response.data;
            });
        },
        async updateMessage(context, data) {
            const axios = await getAxios();
            return axios.post('/message/' + data.type, { id: data.id }).then(response => {
                return response.data;
            });
        },
        async invoices(context, data) {
            const axios = await getAxios();
            return axios.get('/invoices', { params: data }).then(response => {
                return response.data;
            });
        },
        async invoice(context, data) {
            const axios = await getAxios();
            return axios.get('/invoice', {
                responseType: 'blob',
                params: data
            }, {
                headers: {
                    'content-type': 'application/pdf'
                }
            }).then(response => {
                return response.data;
            });
        },
        async updateEntry({state, commit}, entry) {
            const axios = await getAxios();
            if (state.datatype !== "") {
                entry.datatype = state.datatype;
            }

            return axios.post('/saveentry', entry).then(response => {
                if (response.data[entry._type] !== undefined) {
                    commit(entry._type, response.data[entry._type]);
                }

                if (response.data.state !== undefined) {
                    commit('state', response.data.state);
                }

                return response.data;
            });
        },
        async updateOptions({commit}, options) {
            const axios = await getAxios();
            return axios.post('/updateoptions', options).then(response => {
                if(response.data.user !== undefined) {
                    commit('auth/user', response.data.user, {
                        root: true
                    });
                }

                return response.data;
            });
        }
    }
};