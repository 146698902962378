<template>
    <ion-split-pane when="xl" content-id="main">
        <ion-menu menu-id="nav" content-id="main">
            <ion-header>
                <ion-toolbar class="logo">
                    <ion-title color="light" class="ion-hide-xl-down">Phone-Office</ion-title>
                    <ion-title color="light" class="ion-hide-xl-up">Navigation</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list>
                    <ion-item button :routerLink="{ name: 'dashboard' }">
                        <ion-label>Dashboard</ion-label>
                    </ion-item>
                    <ion-item v-if="user && user.level >= 110 && ! user.testphase.live" button color="highlight" :routerLink="{ name: 'account' }">
                        <ion-label>Testzeitraum</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'telephone' }">
                        <ion-label>Telefonnummern</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'links' }">
                        <ion-label>Links</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'mails' }">
                        <ion-label>E-Mail</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'formulars' }" v-if="user && user.customer && user.customer.forms">
                        <ion-label>Formular</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'faq' }" v-if="user && user.customer && user.customer.faq">
                        <ion-label>FAQ</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'contacts' }">
                        <ion-label>Kontakte</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'states' }">
                        <ion-label>Statusmeldung</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'messages' }">
                        <ion-label>Nachrichten</ion-label>
                    </ion-item>
                    <ion-item button :routerLink="{ name: 'options' }">
                        <ion-label>Einstellungen</ion-label>
                    </ion-item>
                    <template v-if="user && user.level >= 110">
                        <ion-item-divider></ion-item-divider>
                        <ion-item button :routerLink="{ name: 'account' }">
                            <ion-label>Accountübersicht</ion-label>
                        </ion-item>
                        <ion-item button :routerLink="{ name: 'companyoptions' }">
                            <ion-label>Firmeneinstellungen</ion-label>
                        </ion-item>
                        <ion-item button :routerLink="{ name: 'companyregister' }">
                            <ion-label>Firmendaten</ion-label>
                        </ion-item>
                        <ion-item button :routerLink="{ name: 'users' }">
                            <ion-label>Mitarbeiterverwaltung</ion-label>
                        </ion-item>
                        <ion-item button :routerLink="{ name: 'invoices' }">
                            <ion-label>Rechnungen / EVN</ion-label>
                        </ion-item>
                    </template>
                    <ion-item-divider></ion-item-divider>
                    <ion-item button :routerLink="{ name: 'logout' }">
                        <ion-label>abmelden</ion-label>
                    </ion-item>
                </ion-list>
            </ion-content>
        </ion-menu>

        <ion-router-outlet id="main"/>
    </ion-split-pane>
</template>

<script>
import {
    IonSplitPane,
    IonMenu,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonRouterOutlet,
    IonList,
    IonItem,
    IonItemDivider,
    IonContent,
    IonLabel
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        isUser() {
            return this.$store.getters['auth/isUser'];
        }
    },
    components: {
        IonSplitPane,
        IonMenu,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonRouterOutlet,
        IonList,
        IonItem,
        IonItemDivider,
        IonContent,
        IonLabel
    }
});
</script>

<style>

</style>