import axios from 'axios';
import Store from './store';
import { getToken } from './Auth';

export async function getAxios() {
    const baseUrl = process.env.VUE_APP_SERVER_URL;
    const token = await getToken();
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 5000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json'
        }
    });

    instance.interceptors.response.use((response) => {
        if (response.headers['content-type'] === "application/json" && response.data.success === undefined) {
            Store.commit('error', 'APP Error: Derzeit werden die Server gewartet.');
        }

        return response;
    }, error => {
        let status = "unbekannt";
        if (error.response !== undefined) {
            status = error.response.status.toString();
        }

        if (status !== "403") {
            Store.commit('error', `APP Error: Verbindung zum Server fehlgeschlagen.`);
        } else {
            Store.commit('layout', 'GuestLayout');
            return Promise.resolve({
                data: {
                    success: false
                }
            });
        }

        return Promise.reject(error);
    });

    return instance;
}