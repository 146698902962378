<template>
    <div>
        <slot name="header"></slot>
        <div class="ion-text-center">
            <alert :type="type" v-if="message !== undefined && message !== ''">
                <div v-html="message"></div>
            </alert>
            <template v-else>
                <br/><br/><br/><br/>
                <ion-spinner></ion-spinner>
                <br/><br/>
                {{ loadingMessage }}
            </template>
        </div>
    </div>
</template>

<script>
import { IonSpinner } from '@ionic/vue';
import Alert from '../components/Alert';
import { defineComponent } from 'vue';

export default defineComponent({
    props: [
        'message',
        'type'
    ],
    computed: {
        loadingMessage() {
            return this.$store.getters['message'];
        }
    },
    components: {
        Alert,
        IonSpinner
    }
});
</script>