import { createApp } from 'vue'
import { IonicVue, menuController } from '@ionic/vue';

import App from './App.vue';
import mitt from 'mitt';
import router from './router';

import Http from './plugins/http';
import Numeral from './plugins/numeral';
import Moment from './plugins/moment';
import Store from "./store";
import { authRedirect } from "./Auth";

import './theme/global.css';
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import './theme/variables.css';

const app = createApp(App)
    .use(IonicVue)
    .use(Store)
    .use(Http)
    .use(Numeral)
    .use(Moment)
    .use(router);

router.beforeEach(async (to, from, next) => {
    menuController.close('nav');

    const redirect = await authRedirect(to, from);
    if (redirect === true) {
        next();
    } else {
        next(redirect);
    }
});

router.isReady().then(() => {
    app.config.globalProperties.emitter = mitt();
    app.mount('#app');
});