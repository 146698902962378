<template>
    <ion-page>
        <ion-router-outlet/>
    </ion-page>
</template>

<script>
import { IonPage, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        IonPage, IonRouterOutlet
    }
});
</script>

<style scoped>
</style>