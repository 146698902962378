<template>
    <ion-app>
        <component :is="layout" v-if="ready"></component>
        <div v-else-if="error !== ''">
            <loader :message="error" type="danger">
                <template v-slot:header>
                    <ion-toolbar class="ion-hide-xl-up" color="dark">
                        <ion-title color="light">Phone-Office</ion-title>
                    </ion-toolbar>
                </template>
            </loader>
        </div>
        <div v-else class="ion-page">
            <loader>
                <template v-slot:header>
                    <ion-toolbar class="ion-hide-xl-up" color="dark">
                        <ion-title color="light">Phone-Office</ion-title>
                    </ion-toolbar>
                </template>
            </loader>
        </div>
    </ion-app>
</template>

<script>
import { IonApp, IonToolbar, IonTitle, toastController, loadingController } from '@ionic/vue';
import Loader from './components/Loader';
import GuestLayout from './views/layouts/GuestLayout';
import MemberLayout from './views/layouts/MemberLayout';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'App',
    computed: {
        layout() {
            return this.$store.getters['layout'];
        },
        ready() {
            return this.$store.getters['ready'];
        },
        error() {
            return this.$store.getters['error'];
        }
    },
    async mounted() {
        this.$store.commit('ready');
    },
    methods: {
        async loading(text) {
            const loader = await loadingController.create({
                message: text || 'Bitte warten ...',
                duration: 1000
            });

            await loader.present();

            return loader;
        },
        async message(type, text) {
            const toast = await toastController.create({
                position: 'top',
                color: type || 'success',
                message: text,
                duration: 3000
            })

            toast.present();
        }
    },
    components: {
        IonApp,
        IonToolbar,
        IonTitle,
        Loader,
        MemberLayout,
        GuestLayout
    }
});
</script>