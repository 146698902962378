import numeral from 'numeral';
import 'numeral/locales/de';

export default {
    install: async (app) => {
        numeral.locale('de');
        numeral.locales.de.delimiters.thousands = ".";

        app.config.globalProperties.$numeral = numeral;
    }
}