import { createStore } from "vuex"

import AuthModule from './modules/Auth';
import RegisterModule from './modules/Register';
import UserModule from './modules/User';
import UsersModule from './modules/Users';
import CompanyModule from './modules/Company';

import { isPlatform } from '@ionic/vue';
import { getAxios } from "../HttpRequest";

const store = createStore({
    modules: {
        auth: AuthModule,
        users: UsersModule,
        register: RegisterModule,
        user: UserModule,
        company: CompanyModule,
    },
    state: {
        layout: "MemberLayout",
        loading: false,
        ready: false,
        defaultMessage: 'Wird geladen ...',
        message: '',
        error: '',
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        ready(state) {
            return state.ready;
        },
        error(state) {
            return state.error;
        },
        loading(state) {
            return state.loading;
        },
        message(state) {
            if (state.message !== "") {
                return state.message;
            }

            return state.defaultMessage;
        }
    },
    mutations: {
        layout(state, layout) {
            state.layout = layout;
        },
        error(state, error) {
            state.error = error;
            state.ready = false;
        },
        loading(state, data) {
            state.loading = true;
            if (data.message !== undefined) {
                state.message = data.message;
            }
        },
        ready(state, isReady) {
            state.loading = false;
            state.message = '';
            state.ready = (isReady !== undefined) ? isReady : true;
        }
    },
    actions: {
        async content(context, data) {
            const axios = await getAxios();
            return axios.get('/content', {params: data});
        },
        layout({commit}, {status}) {
            const prepend = isPlatform('hybrid') ? '' : '';
            commit('layout', prepend + ((status) ? 'MemberLayout' : 'GuestLayout'));
        }
    }
});

export default store;