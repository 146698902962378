import { getAxios } from "../../HttpRequest";

const getDefaultState = () => {
    return {
        company: {}
    }
}

const state = getDefaultState();

export default {
    namespaced: true,
    state,
    getters: {
        company(state) {
            return state.company;
        }
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState())
        },
        company(state, company) {
            state.company = company;
        }
    },
    actions: {
        async package() {
            const axios = await getAxios();
            return axios.get('/customer/package').then(response => {
                return response.data;
            });
        },
        async endTestphase({commit}) {
            const axios = await getAxios();
            return axios.post('/company/testphase').then(response => {
                if(response.data.success) {
                    commit('auth/disableTestphase', null, {
                        root: true
                    });
                }

                return response;
            });
        },
        async loadCompany({commit}) {
            const axios = await getAxios();
            return axios.get('/company').then(response => {
                commit('company', response.data.company);
            });
        },
        async updateOptions({state, commit}) {
            const axios = await getAxios();
            return axios.post('/company', {
                options: state.company.options,
                modtext: state.company.modtext,
                openings: state.company.openings
            }).then(response => {
                commit('company', response.data.company);
                return response.data;
            });
        },
        async updatePackage(context, data) {
            const axios = await getAxios();
            return axios.post('/customer/package', data).then(async response => {
                return response.data;
            });
        },
        async changePackage(context, data) {
            const axios = await getAxios();
            return axios.post('/company/changepackage', data).then(async response => {
                return response.data;
            });
        },
        async checkEmployees(context, data) {
            const axios = await getAxios();
            return axios.post('/company/checkemployees', data).then(async response => {
                return response.data;
            });
        },
        async cancel(context, data) {
            const axios = await getAxios();
            return axios.post('/company/cancel', data).then(async response => {
                return response.data;
            });
        },
        async updateMember(context, data) {
            const axios = await getAxios();
            return axios.post('/member', data).then(response => {
                return response.data;
            });
        },
        async newMember(context, data) {
            const axios = await getAxios();
            return axios.post('/createmember', data).then(response => {
                return response.data;
            });
        }
    }
};