import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Storage, Drivers } from '@ionic/storage';

export async function getStorage() {
    const storage = new Storage();
    return await storage.create({
        name: "phoneoffice",
        driverOrder: [
            CordovaSQLiteDriver._driver,
            Drivers.IndexedDB,
            Drivers.LocalStorage
        ]
    });
}