import { createRouter, createWebHistory } from '@ionic/vue-router';
import useEventsBus from "../Eventbus";

const routes = [
    {
        path: '/',
        name: 'start',
        component: () => import('../views/Home.vue'),
        meta: {
            needAuth: false
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/Register.vue'),
        meta: {
            needAuth: false
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            needAuth: false
        }
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('../views/PasswordRest.vue'),
        meta: {
            needAuth: false
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/member/Logout.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/companyregister',
        name: 'companyregister',
        component: () => import('../views/member/Companyregister.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/member/Dashboard.vue'),
        meta: {
            needAuth: true
        },
        beforeEnter: () => {
            const { emit } = useEventsBus();
            emit('update-dashboard');
        }
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('../views/member/Messages.vue'),
        meta: {
            needAuth: true
        },
        beforeEnter: () => {
            const { emit } = useEventsBus();
            emit('update-messages');
        }
    },
    {
        path: '/telephone',
        name: 'telephone',
        component: () => import('../views/member/Telephone.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/links',
        name: 'links',
        component: () => import('../views/member/Links.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/mails',
        name: 'mails',
        component: () => import('../views/member/Mails.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/formulars',
        name: 'formulars',
        component: () => import('../views/member/Formulars.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('../views/member/Faq.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/states',
        name: 'states',
        component: () => import('../views/member/States.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('../views/member/Contacts.vue'),
        meta: {
            needAuth: true
        },
        beforeEnter: () => {
            const { emit } = useEventsBus();
            emit('update-contacts');
        }
    },
    {
        path: '/options',
        name: 'options',
        component: () => import('../views/member/Options.vue'),
        meta: {
            needAuth: true
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('../views/admin/Account.vue'),
        meta: {
            needAuth: true,
            level: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/admin/Users.vue'),
        meta: {
            needAuth: true,
            level: true
        }
    },
    {
        path: '/users/:id',
        name: 'user',
        component: () => import('../views/admin/User.vue'),
        meta: {
            needAuth: true,
            level: true
        }
    },
    {
        path: '/companyoptions',
        name: 'companyoptions',
        component: () => import('../views/admin/Companyoptions.vue'),
        meta: {
            needAuth: true,
            level: true
        }
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: () => import('../views/admin/Invoices.vue'),
        meta: {
            needAuth: true,
            level: true
        }
    },
    {
        path: '/2fa',
        name: '2fa',
        component: () => import('../views/member/2fa.vue'),
        meta: {
            needAuth: true,
            level: true
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
