<template>
    <ion-card>
        <ion-card-content>
            <ion-text :color="type">
                <slot></slot>
            </ion-text>
        </ion-card-content>
    </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonText } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    props: [ "type" ],
    components: {
        IonCard, IonCardContent, IonText
    }
});
</script>

<style scoped>
</style>